import React from 'react';
import logo from './logo.svg';
import './App.css';
import Sim from './Sim';

function App() {
  return (
<Sim />
  );
}

export default App;
