import React from "react"
import { Metric } from "web-vitals"
import { Behavior, Metrics } from "./SimulationTypes"

function getPhase(metric:Metric){}

function MetroInfo(props: { behavior: Behavior, metric: Metrics }) {
    return <ul>
        <li>station: {props.behavior[props.metric.currentState].stationName}</li>
        <li>phase: {props.behavior[props.metric.currentState].phaseName}</li>
        <li>time: {(props.metric && props.metric.timeTotal.toFixed(2))} s</li>
        <li>velocity: {props.metric && props.metric.velocity.toFixed(1)} m/s</li>
        <li>acceleration: {props.metric && props.metric.acceleration.toFixed(2)} m/s^2</li>
        <li>distance: {props.metric && props.metric.spaceTotal.toFixed(0)} m</li>
        <li>_ cur state: {props.metric.currentState}</li>
    </ul>
}

export default MetroInfo
